import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import axios from "axios";
import BASE_URL from "../../config/config";
import { useQuery } from "@tanstack/react-query";
import SEO from "../../components/SEO/seo-layout";

const Shop = () => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sorts, setSorts] = useState({ type: null, gender: null });

  const applySorting = (newSorts) => {
    setSorts((prev) => ({
      ...prev,
      ...newSorts,
    }));
    setItemOffset(0);
    setCurrentPage(0);
  };

  const {
    data: fishItems,
    status,
    error,
  } = useQuery({
    queryKey: ["fishData", sorts],
    queryFn: async ({ queryKey }) => {
      const { type, gender } = queryKey[1] || {};
      let url = `${BASE_URL}/fish/search?sort=desc`;

      if (type || gender) {
        const qParams = new URLSearchParams();
        if (type) qParams.append("type", type);
        if (gender) qParams.append("gender", gender);
        url += `&${qParams.toString()}`;
      }

      console.log(url, "isi url");

      const response = await axios.get(url);
      return response.data.data;
    },
  });

  const { data: fishTypes, status: typeStatus } = useQuery({
    queryKey: ["fishTypes"],
    queryFn: async () => {
      const response = await axios.get(`${BASE_URL}/types`);
      return response.data.data;
    },
  });

  // Slice fish items for current pagination
  // const paginatedItems = useMemo(() => {
  //   if (!fishItems) return [];
  //   return fishItems.slice(itemOffset, itemOffset + itemsPerPage);
  // }, [fishItems, itemOffset, itemsPerPage]);

  // useEffect(() => {
  //   if (fishItems && itemOffset >= fishItems.length) {
  //     setItemOffset(0); // Reset offset if it exceeds available items
  //     setCurrentPage(0);
  //   }
  // }, [fishItems, itemOffset]);

  return (
    <div className="max-w-container mx-auto px-4 bg-primeColor">
      <SEO
        title="Shop - Get the Cutest Goldfish Now!"
        description="We offer a wide variety of cute and best goldfish. It's Limited Edition, so grab it fast!"
        page="shop"
      />
      <Breadcrumbs title="Products" />
      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-full sml:w-full mdl:w-full lgl:w-full h-full flex flex-col gap-10">
          <ProductBanner
            setItemsPerPage={setItemsPerPage}
            setItemOffset={setItemOffset}
            setCurrentPage={setCurrentPage}
            fishTypes={fishTypes}
            applySorting={applySorting}
            typeStatus={typeStatus}
          />
          <Pagination
            items={fishItems}
            itemsPerPage={itemsPerPage}
            itemOffset={itemOffset}
            setItemOffset={setItemOffset}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            status={status}
            sorts={sorts}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};

export default Shop;
